import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { User, UserNotification } from '../data-models/User';
import * as api from '../services/promoCodesAPI';
import { signOutUser } from './currentUserSlice';
import { NetworkError, getNetworkError } from '../services/networkUtils';
import { Balance, RedeemPetition, Reward } from '../data-models/PromoCodes';

type UserState = {
  rewards: Reward[];
  balance: Balance | null;
  loading: boolean;
  action: [string, boolean];
  error: [boolean, string?];
};

let initialState: UserState = {
  rewards: [],
  balance: null,
  loading: false,
  action: ['', false],
  error: [false],
};

export const getRewards = createAsyncThunk('promoCodes/getRewards', async () => {
  const response = await api.getRewards();
  return response.data as Reward[];
});

export const getUserBalance = createAsyncThunk(
  'promoCodes/getUserBalance',
  async (userId: number) => {
    const response = await api.getUserBalance(userId);
    return response.data as Balance;
  },
);

export const redeemReward = createAsyncThunk(
  'promoCodes/redeemReward',
  async (redeemPetition: RedeemPetition) => {
    const response = await api.redeemReward(redeemPetition);
    return response.data as User[];
  },
);

export const redeemNotification = createAsyncThunk(
  'promoCodes/redeemNotification',
  async (redeem: { email: string; prizeRedeemedCode: string }) => {
    const response = await api.redeemNotification(redeem);
    return response.data as User;
  },
);

export const getInvitationCode = createAsyncThunk(
  'promoCodes/getInvitationCode',
  async (invitation: { userReferrerId: number; userInvitedEmail: string }) => {
    const response = await api.getInvitationCode(invitation);
    return response.data as User;
  },
);

export const inviteUser = createAsyncThunk(
  'promoCodes/inviteUser',
  async (invitation: { senderUserId: number; email: string }) => {
    const response = await api.inviteUser(invitation);
    return response.data;
  },
);

export const completeAppoinment = createAsyncThunk(
  'promoCodes/completeAppoinment',
  async (userEndconsumeEmail: string) => {
    const response = await api.completeAppoinment(userEndconsumeEmail);
    return response.data as User;
  },
);

export const checkNotification = createAsyncThunk(
  'promoCodes/checkNotification',
  async (endConsumerUseEmail: string) => {
    const response = await api.checkNotification(endConsumerUseEmail);
    return response.data as string;
  },
);

const promoCodeSlice = createSlice({
  name: 'promoCodes',
  initialState: initialState,
  reducers: {
    clearStatus(state) {
      (state.action = ['', false]), (state.error = [false, '']);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRewards.fulfilled, (state, { payload }) => {
      state.rewards = payload;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(getRewards.pending, (state, { payload }) => {
      state.loading = true;
      state.action = ['pending', true];

      state.error = [false, undefined];
    });

    builder.addCase(getRewards.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(getUserBalance.fulfilled, (state, { payload }) => {
      state.balance = payload;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(getUserBalance.pending, (state, { payload }) => {
      state.loading = true;
      state.action = ['pending', true];

      state.error = [false, undefined];
    });

    builder.addCase(getUserBalance.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(redeemReward.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(redeemReward.pending, (state, { payload }) => {
      state.loading = true;
      state.action = ['pending', true];

      state.error = [false, undefined];
    });

    builder.addCase(redeemReward.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(redeemNotification.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(redeemNotification.pending, (state, { payload }) => {
      state.loading = true;
      state.action = ['pending', true];

      state.error = [false, undefined];
    });

    builder.addCase(redeemNotification.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(getInvitationCode.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = [false, ''];
    });

    builder.addCase(getInvitationCode.pending, (state, { payload }) => {
      state.action = ['pending', true];
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(getInvitationCode.rejected, (state, action) => {
      state.loading = false;
      state.error = [true, ''];
    });

    builder.addCase(inviteUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = [false, ''];
    });

    builder.addCase(inviteUser.pending, (state, { payload }) => {
      state.loading = true;
      state.action = ['pending', true];

      state.error = [false, undefined];
    });

    builder.addCase(inviteUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(completeAppoinment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = [false, ''];
    });

    builder.addCase(completeAppoinment.pending, (state, { payload }) => {
      state.loading = true;
      state.action = ['pending', true];

      state.error = [false, undefined];
    });

    builder.addCase(completeAppoinment.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(checkNotification.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(checkNotification.pending, (state, { payload }) => {
      state.loading = true;
      state.action = ['pending', true];

      state.error = [false, undefined];
    });

    builder.addCase(checkNotification.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });
  },
});

export const {} = promoCodeSlice.actions;
export default promoCodeSlice.reducer;
