import * as api from '../services/appointmentAPI';
import { AxiosResponse } from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Appointment } from '../data-models/Appointment';
import { signOutUser } from './currentUserSlice';
import moment from 'moment';

export type DateRange = {
  minDate: Date;
  maxDate: Date;
};

type AppointmentState = {
  appointments: Appointment[];
  appointment: Appointment | null;
  loading: boolean;
  error: [boolean, string?];
  dateRange: DateRange;
};

const initialDateRange = {
  minDate: moment().startOf('day').toDate(),
  maxDate: moment().add(65, 'days').endOf('day').toDate(),
};

let initialState: AppointmentState = {
  appointments: [],
  appointment: null,
  loading: false,
  error: [false],
  dateRange: initialDateRange,
};

export const fetchAppointments = createAsyncThunk(
  'schedule/fetchAppointments',
  async ({
    workshopId,
    initialDate,
    finalDate,
    statusCode
  }: {
    workshopId: string;
    initialDate?: string;
    finalDate?: string;
    statusCode?: string;
  }) => {
    const response: AxiosResponse<Appointment[]> = await api.fetchAppointmentsByWorkshop(
      workshopId,
      initialDate,
      finalDate,
      statusCode
    );
    return response;
  },
);

export const fetchAppointmentById = createAsyncThunk(
  'appointments/fetchAppointmentById',
  async ({ appointmentId, workshopId }: { appointmentId: string; workshopId: string }) => {
    const response: AxiosResponse<Appointment> = await api.fetchAppointmentById(
      appointmentId,
      workshopId,
    );
    return response;
  },
);

export const addAppointment = createAsyncThunk(
  'appointments/add',
  async ({ appointment, workshopId, localTimeDifference, coordinates }: { appointment: Appointment; workshopId: string; localTimeDifference: string; coordinates?: string; }) => {
    const response: AxiosResponse<Appointment> = await api.add(appointment, workshopId, localTimeDifference, coordinates);
    return response;
  },
);

export const updateAppointment = createAsyncThunk(
  'appointments/update',
  async ({ appointment, workshopId }: { appointment: Appointment; workshopId: string }) => {
    const response: AxiosResponse<Appointment> = await api.update(appointment, workshopId);
    return response;
  },
);

export const deleteAppointment = createAsyncThunk(
  'appointments/delete',
  async ({ appointmentId, workshopId }: { appointmentId: string; workshopId: string }) => {
    const response: AxiosResponse<number> = await api.erase(appointmentId, workshopId);
    return response;
  },
);

const appointmentSlice = createSlice({
  name: 'appointments',
  initialState: initialState,
  reducers: {
    setAppointment(state, action: PayloadAction<Appointment>) {
      state.appointment = { ...state.appointment, ...action.payload };
    },
    setDateRangeMaximum(state, action: PayloadAction<Date>) {
      const maxDate = moment(action.payload).endOf('day').toDate();
      // console.log(action.payload, maxDate);
      state.dateRange.maxDate = maxDate;
    },
    setDateRangeMinimum(state, action: PayloadAction<Date>) {
      const minDate = moment(action.payload).startOf('day').toDate();
      state.dateRange.minDate = minDate;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAppointments.fulfilled, (state, { payload }) => {
      state.appointments = payload.data.filter(Boolean); // Filters out null values
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(fetchAppointments.pending, (state) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(fetchAppointments.rejected, (state, action) => {
      state.loading = false;
      state.error = [true, action.error.message];
    });

    /*******************************************/

    builder.addCase(fetchAppointmentById.fulfilled, (state, { payload }) => {
      state.appointment = payload.data;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(fetchAppointmentById.pending, (state) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(fetchAppointmentById.rejected, (state, action) => {
      state.loading = false;
      state.error = [true, action.error.message];
    });

    /*******************************************/

    builder.addCase(addAppointment.fulfilled, (state, { payload }) => {
      state.appointments = [...state.appointments, payload.data];
      state.appointment = payload.data;
      state.loading = false;
      state.error = [false, 'added'];
    });

    builder.addCase(addAppointment.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(addAppointment.rejected, (state, action) => {
      state.loading = false;
      state.error = [true, action.error.message];
    });

    /*******************************************/

    builder.addCase(updateAppointment.fulfilled, (state, { payload }) => {
      let appointments: Appointment[] = [...state.appointments];
      let updatedIndex = appointments.findIndex(
        (appointment) => appointment.id === payload.data.id,
      );
      appointments[updatedIndex] = payload.data;
      state.appointments = [...appointments];
      state.appointment = payload.data;
      state.loading = false;
      state.error = [false, 'updated'];
    });

    builder.addCase(updateAppointment.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(updateAppointment.rejected, (state, action) => {
      state.loading = false;
      state.error = [true, action.error.message];
    });

    /*******************************************/

    builder.addCase(deleteAppointment.fulfilled, (state, { payload }) => {
      let temp = [...state.appointments];
      let deletedIndex = temp.findIndex((element) => element.id === payload.data.toString());
      temp.splice(deletedIndex, 1);
      state.appointments = [...temp];
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(deleteAppointment.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(deleteAppointment.rejected, (state, action) => {
      state.loading = false;
      state.error = [true, action.error.message];
    });
    builder.addCase(signOutUser, () => {
      return initialState;
    });
  },
});

export const {
  setAppointment,
  setDateRangeMaximum,
  setDateRangeMinimum,
} = appointmentSlice.actions;
export default appointmentSlice.reducer;
