import { AxiosResponse } from 'axios';
import axios from './axios';
import { EnrollmentQuiz, Workshop, WorkshopService, WorkshopServicePayload } from '../data-models/Workshop';

export const fetchAll = (includeDisabled:boolean=false) => {
  return axios.get(`workshops${includeDisabled? "?includeDisabled=true" : ""}`);
};

export const add = (workshop: Workshop) => {
  return axios.post('workshops', workshop);
};

export const update = (workshop: Workshop) => {
  return axios.put(`workshops/${workshop.id}`, workshop);
};

export const erase = (workshopId: string | number) => {
  return axios.delete(`workshops/${workshopId}`);
};

export const submitQuiz = (quizData: EnrollmentQuiz) => {
  return axios.post(`workshops/${quizData.workshopID}/enrollmentOwnerQuiz`, quizData);
};

/* TODO: check this services */
export const getServices = (workshopId: string) => {
  return axios.get(`workshop/${workshopId}/CustomService`);
};

export const setServices = (workshopId: string, service: WorkshopServicePayload) => {
  return axios.post(`workshop/${workshopId}/CustomService`, service);
};

export const updateServices = (workshopId: string, service: WorkshopServicePayload, workshopServiceId: number) => {
  return axios.put(`workshop/${workshopId}/CustomService/${workshopServiceId}`, service);
};

export const deleteServices = (workshopId: string, workshopServiceId: number) => {
  return axios.delete(`workshop/${workshopId}/CustomService/${workshopServiceId}`);
};
