import { AxiosResponse } from 'axios';
import axios from './axios';
import {
  Day,
  Service,
  VehicleBrand,
  VehicleModel,
  ServiceCategory,
  AppointmentStatus,
  Role,
  IServiceCategory,
  IPackageCategory,
  IVehicleZone
} from '../data-models/Catalogs';
import { WorkshopType } from '../data-models/Workshop';

export interface VehicleMainPurpose {
  vehicleMainPurposeCode: string;
  vehicleMainPurposeDesc: string;
}

export const fetchServiceByWorkshopType = (language: string, workshopTypeCode: string) => {
  return axios.get<Service[]>(
    `catalogs/services?language=${language}&workshopTypeCode=${workshopTypeCode}`,
  );
};

export const fetchPro = () => {
  return axios.post<any>(`carkerpro/login`);
};

export const fetchServices = (language: string): Promise<AxiosResponse<Service[]>> => {
  return axios.get(`catalogs/services?language=${language}`);
};

export const fetchWorkshopServices = (
  language: string,
  code: string,
): Promise<AxiosResponse<Service[]>> => {
  return axios.get(`catalogs/WorkshopTypeServices?language=${language}&workshopTypeCode=${code}`);
};

export const fetchServiceCategories = (
  language: string,
): Promise<AxiosResponse<ServiceCategory[]>> => {
  return axios.get(`catalogs/serviceCategories?language=${language}`);
};

type SasTokenStorage = {
  sasToken: string;
  expire: number;
};
export const fetchSASToken = async () => {
  if (sessionStorage.getItem('sasToken')) {
    const sasTokenObj: SasTokenStorage = JSON.parse(sessionStorage.getItem('sasToken')!);
    if (sasTokenObj.expire > Date.now()) {
      return new Promise((resolve) => resolve({ data: sasTokenObj.sasToken }));
    }
  }
  const request = await axios.get<string>('configurations/sasToken');

  if (request.status >= 200 && request.status < 300) {
    sessionStorage.setItem(
      'sasToken',
      JSON.stringify({
        sasToken: request.data,
        expire: new URLSearchParams(request.data).has('se')
          ? new Date(new URLSearchParams(request.data).get('se')!).valueOf()
          : Date.now() + 300000,
      }),
    );
    return new Promise((resolve) =>
      resolve({
        data: request.data,
      }),
    );
  }
  return new Promise((resolve) => resolve(request));
};

export const fetchDays = (language: string): Promise<AxiosResponse<Day[]>> => {
  return axios.get(`catalogs/days?language=${language}`);
};

export const fetchWorkshopTypes = (language: string): Promise<AxiosResponse<WorkshopType[]>> => {
  return axios.get(`catalogs/workshopTypes?language=${language}`);
};

// export const fetchVehicleBrands = () => {
//   return axios.get<VehicleBrand[]>('catalogs/vehicleBrands');
// };

// export const fetchVehicleModels = (brandCode?: string, year?: number) => {
//   return axios.get<VehicleModel[]>('catalogs/vehicleModels', {
//     params: {
//       ...(brandCode ? { vehicleBrandCode: brandCode } : {}),
//       ...(year ? { modelYear: year } : {}),
//     },
//   });
// };

export const fetchAppointmentStatuses = (
  language: string,
): Promise<AxiosResponse<AppointmentStatus[]>> => {
  return axios.get(`catalogs/appointmentStatus?language=${language}`);
};

export const fetchRoles = (language: string): Promise<AxiosResponse<Role[]>> => {
  return axios.get(`catalogs/roles?language=${language}`);
};

export const fetchQuizSections = () => {
  return axios.get('catalogs/enrollmentQuizSections');
};

export const fetchQuizChecklist = () => {
  return axios.get('catalogs/quizChecklist');
};

/******************************OATS *******************************/

export const fetchVehicleTypes = () => {
  return axios.get<VehicleBrand[]>('catalogs/vehicleTypes');
};

export const fetchVehicleBrands = (vehicleTypeCode: string) => {
  return axios.get<VehicleBrand[]>('catalogs/vehicleBrands', { params: { vehicleTypeCode } });
};

export const fetchVehicleBrandYears = (vehicleTypeCode: string, vehicleBrandCode: string) => {
  return axios.get<VehicleBrand[]>('catalogs/vehicleBrandYears', {
    params: { vehicleTypeCode, vehicleBrandCode },
  });
};

export const fetchVehicleModels = (
  vehicleTypeCode: string,
  vehicleYearCode: string,
  vehicleBrandCode?: string,
) => {
  return axios.get<VehicleBrand[]>('catalogs/vehicleModels', {
    params: { vehicleTypeCode, vehicleYearCode, vehicleBrandCode },
  });
};

export const fetchVehicleFuelTypes = (
  vehicleTypeCode: string,
  vehicleModelCode: string,
  vehicleBrandCode?: string,
  vehicleYearCode?: string,
) => {
  return axios.get<VehicleBrand[]>('catalogs/vehicleFuelTypes', {
    params: { vehicleTypeCode, vehicleModelCode, vehicleBrandCode, vehicleYearCode },
  });
};

export const fetchVehicleDisplacements = (
  vehicleTypeCode: string,
  vehicleBrandCode: string,
  vehicleYearCode: string,
  vehicleModelCode: string,
  vehicleFuelTypeCode: string,
) => {
  return axios.get<VehicleBrand[]>('catalogs/vehicleDisplacements', {
    params: {
      vehicleTypeCode,
      vehicleBrandCode,
      vehicleYearCode,
      vehicleModelCode,
      vehicleFuelTypeCode,
    },
  });
};

export const fetchVehicleEquipments = (
  vehicleTypeCode: string,
  vehicleDisplacementCode: string,
) => {
  return axios.get<VehicleBrand[]>('catalogs/vehicleEquipments', {
    params: { vehicleTypeCode, vehicleDisplacementCode },
  });
};

export const fetchVehicleMainPurposes = (
) => {
  return axios.get<VehicleMainPurpose[]>('catalogs/vehicleMainPurposes?language=ES-MX');
};

/* Categories */
export const fetchServicesCatagories = (
  language: string
): Promise<AxiosResponse<IServiceCategory[]>> => {
  return axios.get(`catalogs/serviceTypesCarousel?language=${language}`);
}
export const fetchPackgesCategories = (
  language: string
): Promise<AxiosResponse<IPackageCategory[]>> => {
  return axios.get(
    `catalogs/servicepackagecombinations?language=${language}`
  );
}
export const fetchPackgesCategoriesTiers = (
  language: string
): Promise<AxiosResponse<any[]>> => {
  return axios.get(
    `catalogs/servicepackagetypes?language=${language}`
  );
}
export const fetchVehicleZones = (
): Promise<AxiosResponse<IVehicleZone[]>> => {
  return axios.get(
    `catalogs/vehicleZones`
  );
}
export const fetchServiceCases = (
): Promise<AxiosResponse<string[]>> => {
  return axios.get(
    `catalogs/serviceCases`
  );
}