export type NetworkError =
  | 'unauthorized'
  | 'forbidden'
  | 'resource_conflict'
  | 'resource_not_found'
  | 'client_error'
  | 'server_error'
  | 'unknown';

export const getNetworkError = (error: any): NetworkError => {
  if (!error.response) {
    return 'unknown';
  }

  const status = error.response.status;

  if (status === 401) {
    return 'unauthorized';
  }
  if (status === 403) {
    return 'forbidden';
  }
  if (status === 404) {
    return 'resource_not_found';
  }
  if (status === 409) {
    return 'resource_conflict';
  }
  if (status >= 400 && status < 500) {
    return 'client_error';
  }
  if (status >= 500 && status < 600) {
    return 'server_error';
  }
  return 'unknown';
};
