import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { User } from '../data-models/User';
import * as api from '../services/userAPI';

type CurrentUserState = {
  user?: User;
};

let initialState: CurrentUserState = { user: undefined };

export const fetchUser = createAsyncThunk('currentUser/fetchUser', async () => {
  let response = await api.fetchUser();
  return response.data;
});

const currentUserSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    signOutUser() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      state.user = payload;
    });
  },
});

export const { signOutUser } = currentUserSlice.actions;
export default currentUserSlice.reducer;
