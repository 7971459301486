import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { User, UserNotification } from '../data-models/User';
import * as api from '../services/userAPI';
import { signOutUser } from './currentUserSlice';
import { NetworkError, getNetworkError } from '../services/networkUtils';

type UserState = {
  users: User[];
  user: User | null;
  passwordUpdated: boolean;
  loading: boolean;
  action: [string, boolean];
  error: [boolean, string?];
};

let initialState: UserState = {
  users: [],
  user: null,
  passwordUpdated: false,
  loading: false,
  action: ['', false],
  error: [false],
};

export const fetchUsers = createAsyncThunk('users/fetchAll', async () => {
  const response = await api.fetchAll();
  return response.data as User[];
});

export const fetchCoworkers = createAsyncThunk(
  'users/fetchAllCoworkers',
  async (workshopId: string) => {
    const response = await api.fetchAllCoworkers(workshopId);
    return response.data as User[];
  },
);

export const fetchUserByAzureId = createAsyncThunk(
  'users/fetchUserByAzureId',
  async (azureId: string) => {
    const response = await api.fetchUserByAzureId(azureId);
    return response.data as User[];
  },
);

export const fetchMe = createAsyncThunk('users/fetchMe', async () => {
  const response = await api.me();
  return response.data as User;
});

export const addUser = createAsyncThunk('users/add', async (user: User) => {
  const response = await api.add(user);
  return response.data as User;
});

export const addCoworker = createAsyncThunk<User, User, { rejectValue: NetworkError }>(
  'users/addCoworker',
  async (user: User, thunkApi) => {
    console.log('user: ', user);
    console.log('thunkApi: ', thunkApi);
    try {
      const response = await api.addCoworker(user);
      console.log('response: ', response);

      return response.data;
    } catch (error) {
      if (error.response) {
        const networkError = getNetworkError(error);
        return thunkApi.rejectWithValue(networkError);
      }
      throw error;
    }
  },
);

export const updateUser = createAsyncThunk('users/update', async (user: any) => {
  const response = await api.update(user);
  return response.data as User;
});

export const deleteUser = createAsyncThunk('users/delete', async (userId: string) => {
  const response = await api.erase(userId, false);
  return response.data as string;
});

export const fullDeleteUser = createAsyncThunk('users/delete', async (data: any) => {
  const response = await api.FullErase(data.id, true, data.reason);
  return response.data as string;
});

export const updatePassword = createAsyncThunk('users/updatePassword', async (password: string) => {
  const response = await api.updatePassword(password);
  return response.data;
});

//ADD USER OWNER FIRST TIME WHEN CREATED
export const addUserOwner = createAsyncThunk('users/addOwner', async (user: User) => {
  const response = await api.add(user);
  return response.data as User;
});

export const updateUserOwner = createAsyncThunk('users/updateOwner', async (user: User) => {
  const response = await api.update(user);
  return response.data as User;
});

export const sendNotification = createAsyncThunk(
  'users/notifications',
  async (notification: UserNotification) => {
    const response = await api.notificaton(notification);
    return response.data as UserNotification;
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    clearStatus(state) {
      (state.action = ['', false]), (state.error = [false, '']);
    },
    setUser(state: UserState, action: {payload: User | null}) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.users = payload;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(fetchUsers.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(fetchUsers.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(fetchCoworkers.fulfilled, (state, { payload }) => {
      state.users = payload;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(fetchCoworkers.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(fetchCoworkers.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(fetchUserByAzureId.fulfilled, (state, { payload }) => {
      state.users = payload;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(fetchUserByAzureId.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(fetchUserByAzureId.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(addUser.fulfilled, (state, { payload }) => {
      state.action = ['added', true];
      state.users = [...state.users, payload];
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(addUser.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(addUser.rejected, (state, { payload }) => {
      state.action = ['added', false];
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(addCoworker.fulfilled, (state, { payload }) => {
      state.action = ['added', true];
      state.users = [...state.users, payload];
      state.loading = false;
      state.error = [false, 'Coworker'];
    });

    builder.addCase(addCoworker.pending, (state, { payload }) => {
      state.action = ['pending', true];
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(addCoworker.rejected, (state, action) => {
      state.action = ['added', false];
      state.loading = false;
      let errorMessage = ['Coworker'];
      const networkError = action.payload;
      if (networkError) {
        if (networkError === 'resource_conflict') {
          errorMessage.push('Ya hay un colaborador registrado con ese correo.');
        }
        if (networkError === 'server_error') {
          errorMessage.push('Algo ha salido mal, vuelve a intentarlo.');
        }
        if (networkError === 'client_error') {
          errorMessage.push('Algo ha salido mal, verifica que toda la información esté correcta.');
        }
      }
      state.error = [true, errorMessage.join('|')];
    });

    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.action = ['updated', true];
      let updatedIndex = state.users.findIndex((element) => element.id === payload.id);
      state.users[updatedIndex] = payload;
      if (state.user !== null && state.user.id === payload.id) state.user = payload;
      state.loading = false;
      state.error = [false, 'updated'];
    });

    builder.addCase(updateUser.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(updateUser.rejected, (state, { payload }) => {
      state.action = ['updated', false];
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(updatePassword.fulfilled, (state, { payload }) => {
      state.action = ['passwordUpdated', true];
      state.loading = false;
      state.error = [false, 'passwordUpdated'];
    });

    builder.addCase(updatePassword.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(updatePassword.rejected, (state, { payload }) => {
      state.action = ['passwordUpdated', false];
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(deleteUser.fulfilled || fullDeleteUser.fulfilled, (state, { payload }) => {
      let index = state.users.findIndex((element) => element.id === payload.toString());
      state.users = [...state.users.slice(0, index), ...state.users.slice(index + 1)];
      //state.users = [...newUsers];
      state.action = ['deleted', true];
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(deleteUser.pending || fullDeleteUser.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(deleteUser.rejected || fullDeleteUser.rejected, (state, { payload }) => {
      state.action = ['deleted', false];
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(fetchMe.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.loading = false;
      state.error = [false, ''];
    });

    builder.addCase(fetchMe.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, ''];
    });

    builder.addCase(fetchMe.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Ocurrió un error obteniendo al usuario. Por favor inicia sesión.'];
    });

    builder.addCase(sendNotification.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = [false, 'Notification'];
    });

    builder.addCase(sendNotification.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, ''];
    });

    builder.addCase(sendNotification.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Notification'];
    });
    builder.addCase(signOutUser, () => {
      return initialState;
    });
  },
});

export const { clearStatus, setUser } = userSlice.actions;
export default userSlice.reducer;
