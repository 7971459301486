import axios from './axios';
import { User } from '../data-models/User';
import { UserNotification } from '../data-models/User';

export const fetchAll = () => {
  return axios.get('users');
};

export const fetchAllCoworkers = (workshopId: string) => {
  return axios.get(`users?workshopid=${workshopId}`);
};

export const fetchUserByAzureId = (azureId: string) => {
  return axios.get(`users?azureCode=${azureId}`);
};

export const fetchUser = () => {
  return axios.get<User>('users/me');
};

export const add = (user: User) => {
  return axios.post('users', user);
};

export const addCoworker = (user: User) => {
  return axios.post<User>('users?addToAD=true', user);
};

export const update = (user: any) => {
  return axios.put(`users/${user.id}`, user);
};

export const erase = (userId: string | number, fullDelete: boolean) => {
  return axios.delete(`users/${userId}?fullDelete=${fullDelete}`);
};
export const FullErase = (userId: string | number, fullDelete: boolean, deleteReason: any) => {
  return axios.delete(`users/${userId}?fullDelete=${fullDelete}&deleteReason=${deleteReason}`);
};

export const me = () => {
  return axios.get(`users/me`);
};

export const updatePassword = (password: string, isNewUser?: boolean) => {
  return axios.put(`/b2c/users/me${isNewUser ? `?isNewUser=${isNewUser}` : '' }`, { password: password });
};

export const notificaton = (notification: UserNotification) => {
  return axios.post('/notifications', notification);
};
