import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  Service,
  Day,
  ServiceCategory,
  VehicleBrand,
  AppointmentStatus,
  IPackageCategory,
  IServiceCategory,
  IVehicleZone
} from '../data-models/Catalogs';
import { WorkshopType } from '../data-models/Workshop';
import * as catalogsAPI from '../services/catalogsAPI';
import { signOutUser } from './currentUserSlice';

type CatalogsState = {
  workshopTypes: WorkshopType[];
  days: Day[];
  services: Service[];
  _serviceCategories: ServiceCategory[];
  appointmentStatuses: AppointmentStatus[];
  suggestedServices: Service[];
  vehicleBrands: VehicleBrand[];
  servicesCategories: IServiceCategory[];
  packagesCategories: IPackageCategory[];
  vehicleZones: IVehicleZone[];
  serviceCases: string[];
  status: 'loading' | 'succeeded' | 'failed';
  error?: string;
};

let initialState: CatalogsState = {
  workshopTypes: [],
  days: [],
  services: [],
  _serviceCategories: [],
  appointmentStatuses: [],
  suggestedServices: [],
  servicesCategories: [],
  packagesCategories: [],
  vehicleZones: [],
  serviceCases: [],
  vehicleBrands: [],
  status: 'loading',
  error: undefined,
};

export const fetchCatalogs = createAsyncThunk(
  'catalogs/fetchCatalogs',
  async (language: string) => {
    const [
      typesResponse,
      daysResponse,
      servicesResponse,
      categoriesResponse,
      appointmentStatusesResponse,
      servicesCategoriesResponse,
      packagesCategoriesResponse,
      vehicleZonesResponse,
      serviceCasesResponse
    ] = await Promise.all([
      catalogsAPI.fetchWorkshopTypes(language),
      catalogsAPI.fetchDays(language),
      catalogsAPI.fetchServices(language),
      catalogsAPI.fetchServiceCategories(language),
      catalogsAPI.fetchAppointmentStatuses(language),
      catalogsAPI.fetchServicesCatagories(language),
      catalogsAPI.fetchPackgesCategories(language),
      catalogsAPI.fetchVehicleZones(),
      catalogsAPI.fetchServiceCases()
    ] as const);

    return {
      types: typesResponse.data,
      days: daysResponse.data,
      services: servicesResponse.data,
      categories: categoriesResponse.data,
      appointmentStatuses: appointmentStatusesResponse.data,
      servicesCategories: servicesCategoriesResponse.data,
      packagesCategories: packagesCategoriesResponse.data,
      vehicleZones: vehicleZonesResponse.data,
      serviceCases: serviceCasesResponse.data,
    };
  },
);

export const fetchSuggestedServices = createAsyncThunk(
  'catalogs/fetchSuggestedServices',
  async (args: { workshopTypeCode: string; language: string }) => {
    let response = await catalogsAPI.fetchServiceByWorkshopType(
      args.language,
      args.workshopTypeCode,
    );
    return response.data;
  },
);

export const fetchPro = createAsyncThunk('catalogs/fetchPro', async () => {
  let response = await catalogsAPI.fetchPro();
  return response.data;
});

/* TODO: Rename this */
const serviceCarouselOrder = [
  "IDK",
  "MMG",
  "LFyS",
  "Moto",
  "HyP",
  "TyD",
  "BySE",
  "Tunning",
  "MEyR",
  "LEyE",
  "A/C",
  "VVyS",
  "Diesel",
  "Other",
];
export const fetchServicesCatagories = createAsyncThunk(
  "catalogs/fetchServicesCatagories",
  async () => {
    const response = await catalogsAPI.fetchServicesCatagories("ES-MX");
    if (response.status !== 200) return [];
    // Sort services
    const newArray: IServiceCategory[] = [];
    const notFoundedArray: IServiceCategory[] = [];
    response.data.forEach((service) => {
      let alteredService = { ...service, reason: [] };
      const index = serviceCarouselOrder.indexOf(
        alteredService.serviceTypeCode
      );
      if (index === -1) notFoundedArray.push(alteredService);
      if (index !== -1) newArray[index] = alteredService;
    });
    return [...newArray, ...notFoundedArray]; 
  }
);

export const fetchPackgesCategories = createAsyncThunk(
  "catalogs /fetchPackgesCategories",
  async () => {
    const response = await catalogsAPI.fetchPackgesCategories("ES-MX");
    if (response.status !== 200) return [];
    return response.data; 
  }
);

const catalogsSlice = createSlice({
  name: 'catalogs',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCatalogs.fulfilled, (state, { payload }) => {
      const { types, days, services, categories, appointmentStatuses, servicesCategories, packagesCategories, vehicleZones, serviceCases /*  vehicleBrands */ } = payload;
      state.workshopTypes = types;
      state.days = days;
      state.services = services;
      state._serviceCategories = categories;
      state.appointmentStatuses = sortedAppointmentStatuses(appointmentStatuses);
      state.servicesCategories = servicesCategories.filter((service) => service.serviceTypeCode !== "IDK").sort((a, b) => a.typePosition - b.typePosition);
      state.packagesCategories = packagesCategories;
      state.vehicleZones = vehicleZones;
      state.serviceCases = serviceCases;
      state.status = 'succeeded';
      state.error = undefined;
    });

    builder.addCase(fetchCatalogs.pending, (state, { payload }) => {
      state.status = 'loading';
      state.error = undefined;
    });

    builder.addCase(fetchCatalogs.rejected, (state, { payload }) => {
      state.status = 'failed';
      state.error = 'Failed to load catalogs';
    });

    builder.addCase(fetchSuggestedServices.pending, (state, { payload }) => {
      state.suggestedServices = [];
    });
    builder.addCase(fetchSuggestedServices.fulfilled, (state, { payload }) => {
      state.suggestedServices = payload;
    });
    builder.addCase(signOutUser, () => {
      return initialState;
    });
  },
});

export default catalogsSlice.reducer;

const sortedAppointmentStatuses = (statuses: AppointmentStatus[]): AppointmentStatus[] => {
  const order = ['CREAT', 'APPO', 'RECEP', 'DIAG', 'PROG', 'READY', 'CLOS', 'CANCE'];
  return statuses.sort((a, b) => {
    return order.indexOf(a.code) - order.indexOf(b.code);
  });
};
