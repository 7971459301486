import { RedeemPetition } from '../data-models/PromoCodes';
import axios from './axios';

export const getRewards = () => {
  return axios.get('promoCodes/rewards');
};

export const getUserBalance = (userId: number) => {
  return axios.get(`promoCodes/balance/${userId}`);
};

export const redeemReward = (redeemPetition: RedeemPetition) => {
  return axios.post(`promoCodes/redeem`, redeemPetition);
};

export const redeemNotification = (redeem: { email: string; prizeRedeemedCode: string }) => {
  return axios.post(`promoCodes/redeemnotification`, redeem);
};

export const getInvitationCode = (invitation: {
  userReferrerId: number;
  userInvitedEmail: string;
}) => {
  return axios.post(`promoCodes/code`, invitation);
};

export const inviteUser = (invitation: { senderUserId: number; email: string }) => {
  return axios.post(`promoCodes/invitations`, invitation);
};

export const completeAppoinment = (endConsumerUserEmail: string) => {
  return axios.post(`/promoCodes/completeappointment`, { endConsumerUserEmail });
};

export const checkNotification = (endConsumerUserEmail: string) => {
  return axios.post(`/promoCodes/checkinnotification`, { endConsumerUserEmail });
};
