import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { EnrollmentQuiz, Workshop, WorkshopType, WorkshopService, StagedWorkshopService, WorkshopServicePayload } from '../data-models/Workshop';
import * as workshopAPI from '../services/workshopAPI';
import { signOutUser } from './currentUserSlice';

const SELECTED_WORKSHOP_KEY = "selectedWorkshop";
const RECENTRLY_ADDED_KEY = "recentlyAddedIndex";

export const translateStagedServiceToPayload = (stagedService: WorkshopServicePayload): WorkshopServicePayload => {
  return {
    ...(!!stagedService.workshopServiceId ? { workshopServiceId: stagedService.workshopServiceId } : {}),
    workshopServiceName: stagedService.workshopServiceName,
    workshopServiceType: stagedService.workshopServiceType,
    workshopServiceStatus: stagedService.workshopServiceStatus,
    vehicleServiceZones: stagedService.vehicleServiceZones,
    ...(stagedService.workshopServiceType === "MAN" ? {
      servicePackageCode: stagedService.servicePackageCode,
      serviceTiers: stagedService.serviceTiers
    } : {}),
    ...(stagedService.workshopServiceType === "REP" ? {
      srvcTypeCode: stagedService.srvcTypeCode,
      basePrice: stagedService.basePrice,
      serviceComments: stagedService.serviceComments,
      serviceCases: stagedService.serviceCases
    } : {}),
  }
}

export const setLocalStorage = (key: string, value: any): boolean => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    // A more advanced implementation would handle the error case
    console.error(error);
    return false;
  }
}

export const getLocalStorage = (key: string): any => {
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : undefined;
  } catch (error) {
    // If error also return initialValue
    console.error(error);
    return undefined;
  }
}

export const clearWorkshopStorage = () => {
  localStorage.removeItem(SELECTED_WORKSHOP_KEY);
  localStorage.removeItem(RECENTRLY_ADDED_KEY);
}

type WorkshopState = {
  workshops: Workshop[];
  workshopTypes: WorkshopType[];
  loading: boolean;
  action: [string, boolean];
  error: [boolean, string?];
  index: number;
  recentlyAddedIndex: undefined | number;
  services: WorkshopService[];
  stagedServices: StagedWorkshopService[];
};

const initialState: WorkshopState = {
  workshops: [],
  workshopTypes: [],
  loading: false,
  action: ['', false],
  error: [false, ''],
  index: getLocalStorage(SELECTED_WORKSHOP_KEY) || 0,
  recentlyAddedIndex: getLocalStorage(RECENTRLY_ADDED_KEY) || undefined,
  services: [],
  stagedServices: []
};

if (getLocalStorage(SELECTED_WORKSHOP_KEY) === undefined) {
  setLocalStorage(SELECTED_WORKSHOP_KEY, initialState.index);
}

export const fetchWorkshops = createAsyncThunk('workshops/fetchAll', async (includeDisabled: boolean = false) => {
  const response = await workshopAPI.fetchAll(includeDisabled);
  return response.data as Workshop[];
});

export const addWorkshop = createAsyncThunk('workshops/add', async (workshop: Workshop) => {
  const response = await workshopAPI.add(workshop);
  return response.data as Workshop;
});

export const updateWorkshop = createAsyncThunk('workshops/update', async (workshop: Workshop) => {
  const response = await workshopAPI.update(workshop);
  return response.data as Workshop;
});

export const deleteWorkshop = createAsyncThunk(
  'workshops/delete',
  async (workshopId: string | number) => {
    const response = await workshopAPI.erase(workshopId);
    return response.data as Workshop;
  },
);

export const submitQuiz = createAsyncThunk(
  'workshops/submitQuiz',
  async (quizData: EnrollmentQuiz) => {
    const response = await workshopAPI.submitQuiz(quizData);
    return response.data;
  },
);

export const workshopSelected = createAsyncThunk(
  'workshop/workshopSelected',
  async (index: number) => {
    return index;
  },
);

/* TODO: point to get services endpoint */
export const getWorkshopServices = createAsyncThunk(
  'services/get',
  async (_, thunkAPI) => {
    const { workshop: { index, workshops } } = thunkAPI.getState() as { workshop: WorkshopState };
    const response = await workshopAPI.getServices(workshops[index].id || "0");
    return response.data;
  },
);

export const setWorkshopServices = createAsyncThunk(
  'services/set',
  async (_, thunkAPI) => {
    const { workshop: { index, workshops, stagedServices } } = thunkAPI.getState() as { workshop: WorkshopState };
    const stagedService = stagedServices.find((stagedService) => stagedService.service.workshopServiceId === 0);
    if (stagedService && stagedService.isValid && workshops[index] && workshops[index]?.id) {
      const payload: WorkshopServicePayload = translateStagedServiceToPayload(stagedService.service);
      const response = await workshopAPI.setServices(workshops[index].id || "0", payload);
      return response.data;
    }
  },
);

export const updateWorkshopServices = createAsyncThunk(
  'services/update',
  async (_, thunkAPI) => {
    const { workshop: { index, workshops, stagedServices } } = thunkAPI.getState() as { workshop: WorkshopState };
    const stagedServicesForUpdate = stagedServices.filter((stagedService) => stagedService.service.workshopServiceId !== 0 && stagedService.isDirty);
    if (stagedServices && stagedServicesForUpdate.every((staged) => staged.isValid) && workshops[index] && workshops[index]?.id) {
      const payloads: WorkshopServicePayload[] = stagedServicesForUpdate.map((stagedService) => translateStagedServiceToPayload(stagedService.service));
      const response = Promise.all(
        payloads.map(
          async (payload) => await workshopAPI.updateServices(
            workshops[index].id || "0",
            payload, 
            payload.workshopServiceId!
          ).then((response) => response.data).catch(e => false)
        )
      );
      return response;
    }
  },
);

export const deleteWorkshopServices = createAsyncThunk(
  'services/delete',
  async (service: WorkshopService, thunkAPI) => {
    const { workshop: { index, workshops } } = thunkAPI.getState() as { workshop: WorkshopState };
    const response = await workshopAPI.deleteServices(workshops[index].id || "0", service.workshopServiceId);
    return response ? service.workshopServiceId : -1;
  },
);

const workshopSlice = createSlice({
  name: 'workshop',
  initialState: initialState,
  reducers: {
    clearRecentlyAdded(state) {
      state.recentlyAddedIndex = undefined;
      localStorage.removeItem(RECENTRLY_ADDED_KEY);
    },
    clearStagedServices(state) {
      state.stagedServices = [];
    },
    setStagedService(state, action: { payload: StagedWorkshopService }) {
      const { payload } = action;
      if (state.stagedServices.find((stasgedService) => stasgedService.service.workshopServiceId === payload.service.workshopServiceId) === undefined) {
        state.stagedServices = [...(state.stagedServices), payload];
      } else {
        state.stagedServices = state.stagedServices.map((stagedService) => {
          if (stagedService.service.workshopServiceId === payload.service.workshopServiceId) {
            return payload;
          } else {
            return stagedService;
          }
        });
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkshops.fulfilled, (state, { payload }) => {
      state.workshops = payload;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(fetchWorkshops.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(fetchWorkshops.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Ha ocurrido un error al conseguir los talleres.'];
    });

    builder.addCase(workshopSelected.fulfilled, (state, { payload }) => {
      state.index = payload;
      state.loading = false;
      state.error = [false, undefined];
      setLocalStorage(SELECTED_WORKSHOP_KEY, state.index);
    });

    builder.addCase(workshopSelected.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(workshopSelected.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Ha ocurrido un error al conseguir los talleres.'];
    });

    builder.addCase(addWorkshop.fulfilled, (state, { payload }) => {
      state.action = ['added', true];
      state.workshops = [...state.workshops, { ...payload, isClone: true }];
      state.recentlyAddedIndex = state.workshops.length - 1;
      state.loading = false;
      state.error = [false, 'Taller añadido correctamente.'];
    });

    builder.addCase(addWorkshop.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(addWorkshop.rejected, (state, { payload }) => {
      state.action = ['added', false];
      state.loading = false;
      state.error = [true, 'Ha ocurrido un error al añadir el taller.'];
    });

    builder.addCase(updateWorkshop.fulfilled, (state, { payload }) => {
      state.action = ['updated', true];
      const index = state.workshops.findIndex((element) => element.id === payload.id);
      if (payload.dsrStatusCode === null) delete payload.dsrStatusCode;
      if (payload.dsrStatusDesc === null) delete payload.dsrStatusDesc;
      state.workshops[index] = { ...state.workshops[index], ...payload };
      state.loading = false;
      state.error = [false, 'Taller actualizado correctamente.'];
    });

    builder.addCase(updateWorkshop.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(updateWorkshop.rejected, (state, { payload }) => {
      state.action = ['updated', false];
      state.loading = false;
      state.error = [true, 'Ha ocurrido un error al actualizar el taller.'];
    });

    builder.addCase(deleteWorkshop.fulfilled, (state, { payload }) => {
      state.action = ['deleted', true];
      state.loading = false;
      state.error = [false, 'Taller eliminado.'];
    });

    builder.addCase(deleteWorkshop.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(deleteWorkshop.rejected, (state, { payload }) => {
      state.action = ['deleted', false];
      state.loading = false;
      state.error = [true, 'Ha ocurrido un error al eliminar el taller.'];
    });
    builder.addCase(signOutUser, () => {
      return initialState;
    });

    builder.addCase(submitQuiz.fulfilled, (state, { payload }) => {
      // state.action = ["quiz", false];
      state.loading = false;
      state.error = [false, 'submit'];
      state.action = ['submit', false];
    });

    builder.addCase(submitQuiz.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(submitQuiz.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Al parecer tus respuestas no han sido enviadas, intenta nuevamente.'];
    });

    builder.addCase(getWorkshopServices.fulfilled, (state, { payload }) => {
      state.services = payload;
      state.loading = false;
      state.error = [false, undefined];
      state.action = ['', false];
    });

    builder.addCase(getWorkshopServices.pending, (state) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(getWorkshopServices.rejected, (state, erd) => {
      state.loading = false;
      state.error = [true, 'Se produjo un error al consultar los servicios.'];
    });

    builder.addCase(setWorkshopServices.fulfilled, (state, { payload }) => {
      state.services = [...state.services, payload];
      state.stagedServices = state.stagedServices.filter((stagedService) => stagedService.service.workshopServiceId !== 0);
      state.loading = false;
      state.error = [false, undefined];
      state.action = ['', false];
    });

    builder.addCase(setWorkshopServices.pending, (state) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(setWorkshopServices.rejected, (state, { error }) => {
      console.info("error", error);
      state.loading = false;
      state.error = [
        true,
        error !== undefined && error?.message && error?.message.includes('409') ?
          'Ya existe un servicio con el nombre ingresado.' :
          'Se produjo un error al crear el servicio.'];
    });

    builder.addCase(updateWorkshopServices.fulfilled, (state, action : { payload: WorkshopService[] }) => {
      const { payload } = action;
      state.services = state.services.map((service) => {
        const payloadIndex = payload.findIndex((payloadService) => payloadService.workshopServiceId === service.workshopServiceId);
        if (payloadIndex === -1) {
          return service;
        } else {
          return payload[payloadIndex];
        }
      });
      state.loading = false;
      state.error = [false, undefined];
      state.action = ['', false];
    });

    builder.addCase(updateWorkshopServices.pending, (state) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(updateWorkshopServices.rejected, (state, { error }) => {
      console.info("error", error);
      state.loading = false;
      state.error = [
        true,
        error !== undefined && error?.message && error?.message.includes('409') ?
          'Ya existe un servicio con el nombre ingresado.' :
          'Se produjo un error al crear el servicio.'];
    });

    builder.addCase(deleteWorkshopServices.fulfilled, (state, action : { payload: number }) => {
      const { payload } = action;
      state.services = state.services.filter((service) => service.workshopServiceId !== payload)
      state.loading = false;
      state.error = [false, undefined];
      state.action = ['', false];
    });

    builder.addCase(deleteWorkshopServices.pending, (state) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(deleteWorkshopServices.rejected, (state, { error }) => {
      state.loading = false;
      state.error = [
        true,
        'Se produjo un error al eliminar el servicio.'];
    });
  },
});

export const {
  clearRecentlyAdded,
  clearStagedServices,
  setStagedService
} = workshopSlice.actions;
export default workshopSlice.reducer;
